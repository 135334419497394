import Vue from 'vue'
import axios from 'axios'

// import TurbolinksAdapter from 'vue-turbolinks'
import HomePageSearch from '../vue/search/HomePageSearch.vue'

// Vue.use(TurbolinksAdapter, { destroyEvent: 'turbolinks:visit' })

document.addEventListener('DOMContentLoaded', () => {
  let el = document.getElementById('vue-home-page-search')

  if (el) {
    Vue.prototype.$axios = axios
    const token = el.dataset.authToken
    localStorage.setItem('token', el.dataset.authToken)
    Vue.prototype.$axios.defaults.baseURL = '/v3'

    Vue.prototype.$axios.defaults.headers.common = {
      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      Authorization: token,
      Accept: 'application/json',
      'Content-Type': 'application/json; charset=utf-8'
    }

    const props = {
      location: el.dataset.location,
      currentLocation: el.dataset.currentLocation,
      isLoggedIn: el.dataset.isLoggedIn
    }

    new Vue({
      el: el,
      render: h => h(HomePageSearch, { props })
    })
  }
})
