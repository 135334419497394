<script>
import SearchResults from "./SearchResults.vue";
import flashImage from '../../images/icon-energy.svg'
import logo from '../../images/logo.svg'

export default {
  props: ["placeholderArray", 'location', 'currentLocation', 'isLoggedIn'],
  components: {
    SearchResults
  },
  data: function () {
    return {
      searchModal: false,
      timer: "",
      placeholderString: "",
      placeholderIndex: 0,
      placeholderCount: 0,
      placeholderSelectedText: "",
      placeholderText: "",
      placeholderAnimationSpeed: 50,
      placeholderAnimationDelay: 2000,
      collections: {
        art_types: [],
        artists_in_location: [],
        other_artists: [],
        specialities: [],
        currentUrlLocation: this.currentLocation
      },
      query: '',
      flashImage: flashImage,
      logo: logo
    };
  },
  watch: {
    query (ss) {
      if (document.querySelector(".main-container").clientWidth < 640) {
        this.searchModal = true;
      } else {
        this.searchModal = false;
      }
    },
    searchModal (ss) {
      if (ss) {
        document.querySelector("body").style = "overflow: hidden;";
      } else {
        document.querySelector("body").style = null;
      }
    }
  },
  created () {
    this.pauseAndRunPlaceholder(0);
  },
  methods: {
    find(event) {
      this.query = event.target.value || ''
      const new_query = this.query.trim()
      if (new_query) {
        const params = {
          location: this.location,
          query: new_query
        }

        this.$axios.get('search/suggestions', {params: params})
          .then(response => {
            this.collections = response.data
            this.collections.currentUrlLocation = this.currentLocation;
          })
      }
    },
    transferText() {
      if (this.query.trim()) {
        localStorage.setItem("searchQuery", this.query);
      }
    },
    closeSearch() {
      this.query = '';
      setTimeout(() => {
        this.searchModal = false;
      }, 100);
    },
    focus() {
      console.log('focus')
    },
    changePlaceholderString() {
      if (!this.placeholderArray) {
        return null;
      }

      if (this.placeholderCount == this.placeholderArray.length) {
        this.placeholderCount = 0;
      }
      this.placeholderSelectedText = this.placeholderArray[
        this.placeholderCount
      ];
      this.placeholderText = this.placeholderSelectedText.slice(
        0,
        ++this.placeholderIndex
      );
      this.placeholderString = this.placeholderText;
      if (this.placeholderText.length === this.placeholderSelectedText.length) {
        this.placeholderCount++;
        this.placeholderIndex = 0;
        this.pauseAndRunPlaceholder(this.placeholderAnimationDelay);
      }
    },
    pauseAndRunPlaceholder(delay) {
      clearInterval(this.timer);
      setTimeout(() => {
        this.timer = setInterval(
          this.changePlaceholderString,
          this.placeholderAnimationSpeed
        );
      }, delay);
    }
  },
  beforeDestroy() {
    document.querySelector("body").style = null;
  }
};
</script>


<template>
  <div class="hero-search" :class="{ modal: searchModal }">
    <span style="width: 100%; position: relative;">
      <input
        :value="query"
        class="hero-search__input typing"
        :placeholder="placeholderString"
        type="text"
        @input="find"
      />
      <img
        v-if="query"
        @click="closeSearch"
        src="../../images/close-orange.svg"
        class="hero-search__close-search"
        :style="!isLoggedIn ? `right: 26%;` : `right: 21%;` "
      />
      <button @click="transferText" class="btn search-with-ai">
        <a href="/ai">
          <span><img :src="logo" class="me-2 d-flex" /></span>
          <span> Search with AI </span>
        </a>
      </button>
    </span>
    <div class="hp-btns">
      <button v-if="!isLoggedIn" id="get-instant-quotes-btn" class="btn primary get-instant-quote d-flex align-items-center justify-content-center">
      <span><img :src="flashImage" class="me-2 d-flex" /></span>
      <span> Get Instant Quotes </span>
      </button>
        <button @click="transferText" class="btn search-with-ai-mobile">
          <a href="/ai">
            <span><img :src="logo" class="me-2 d-flex" /></span>
            <span> Search with AI </span>
          </a>
        </button>
    </div>

    <search-results :query="query" :collections="collections"/>
  </div>
</template>
